body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 15px;
}

a {
  color: #0498f3;
}

.map-box-container {
  margin-bottom: 15px;
}

.subscription-update-title {
  font-size: 30px;
  line-height: 1.2;
  color: #37474f;
  font-weight: 400;
  margin-bottom: 20px;
}

.action-title {
  color: #0498f3;
  cursor: pointer;
}

.action-title:hover {
  color: #76dcbc;
  text-decoration: underline;
  text-decoration-color: #76dcbc;
}

.policy-domain-item,
.lan-subnet-name,
.billing-plan-name {
  color: #0498f3;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #0498f3;
}

.policy-domain-item:hover,
.lan-subnet-name:hover,
.billing-plan-name:hover {
  color: #76dcbc;
  text-decoration-color: #76dcbc;
}

.policy-categories-container {
  display: flex;
  flex-wrap: wrap;
}

.policy-categories-container.row {
  margin-right: -10px;
  margin-left: -10px;
}

.billing-org-row,
.billing-org-name {
  display: inline-flex;
  align-items: center;
}

.billing-org-select {
  display: inline-flex;
  width: 120px;
  margin-right: 8px;
}

.bp3-input.bp3-fill.billing-org-input {
  width: 70px;
}

.billing-org-input-label {
  display: inline-block;
  margin-right: 7.5px;
  margin-left: 7.5px;
}

.billing-org-action-buttons {
  width: 100px;
}

.invoice-details-desc {
  padding: 4px 0px;
}

.btn-clear-policies,
.action-text {
  background-color: transparent;
  color: #0498f3;
  padding: 0;
  border: none;
  text-decoration: underline;
  transition: all 0.2s linear;
  cursor: pointer;
}

.policies-list-header {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 990px) {
  .policies-list-controls-right {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: 10px;
  }
}

.policies-list-controls {
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;
  margin-right: -10px;
  padding-bottom: 10px;
}

.policies-list-controls,
.nowrap {
  white-space: nowrap;
}

.query-log-controls {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 15px 0px;
  align-items: center;
}

.query-log-search {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 300px;
  margin-right: 10px;
}

.query-log-deployment-name {
  display: flex;
}

.query-log-fqdn-name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.scheduled-fields-container {
  display: flex;
  margin-top: -20px;
  margin-right: -20px;
}

.scheduled-field-item {
  flex: 1;
  padding-top: 20px;
  padding-right: 20px;
}

.pin-info-link {
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #fff;
  transition: all 0.2s linear;
}

.pin-info-link:hover,
.btn-clear-policies:hover,
.action-text:hover {
  color: #76dcbc;
  text-decoration-color: #76dcbc;
}

.install-user-agents-list {
  display: flex;
  justify-content: space-between;
}

.column-title-sort-button {
  border: none;
  background-color: transparent;
}

.user-agent-section {
  flex: 0 0 50%;
}

.user-agent-section > .section-title {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.install-dns-option {
  display: flex;
  align-items: center;
  padding: 7px 0px;
}

.secret-key-group > input {
  height: 36px;
  line-height: 36px;
}

.install-agent-step {
  font-weight: bold;
  color: #0096fb;
}

.install-dns-roster {
  list-style: none;
  padding-left: 7px;
  margin: 0;
}

.install-dns-content {
  margin-left: 7px;
}

.roaming-client-title {
  font-weight: 500;
}

.installer-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.secret-key-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;
  margin-right: -15px;
}

.secret-key-input-wrapper {
  flex: 0 0 250px;
  margin: 15px 15px 0px 0px;
}

.disable-user-agent-container {
  display: flex;
}

.select-site-input-wrapper {
  flex: 0 0 40%;
  max-width: 320px;
  margin: 10px 30px 0px 0px;
}

.disable-pin-wrapper {
  flex: 0 0 55%;
  max-width: 320px;
  margin-top: 10px;
}

.disable-pin {
  font-weight: 600;
  color: #37474f;
  margin-bottom: 5;
}

.disable-pin-more-link {
  font-size: 13px;
}

.select-site-content {
  flex-grow: 1;
  text-align: left;
  margin: 0 5px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter-select {
  width: 210px;
  padding: 15px 15px 0px 0px;
}

.select-period-input {
  width: 180px;
}

.select-period-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.select-custom-period {
  padding: 10px;
  display: flex;
  align-items: center;
}

.select-period-buttons {
  display: flex;
}

.submit-select-period {
  margin-left: 5px;
}

.input-form-group-wrapper {
  flex: 1 0;
  max-width: 300px;
  margin-right: 5px;
}

input {
  font-family: inherit;
}

.site-menubar {
  z-index: 0;
  min-height: 100vh;
}

.bp3-callout:not(:last-child) {
  margin-bottom: 20px;
}

.bp3-icon:not([class*="bp3-intent"]) {
  color: #76838f;
}

.bp3-input-group > .bp3-icon {
  margin: 9px;
}

.bp3-input-group .bp3-input:not(:first-child) {
  padding-left: 32px;
}

.graph-nowrap-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.controls-toolbar,
.lan-subnets-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.deployment-toolbar-controls {
  display: flex;
  padding-bottom: 10px;
}

.deployment-toolbar-search {
  width: 200px;
  margin-right: 10px;
}

.deployment-toolbar-select {
  width: 200px;
}

.controls-toolbar .btn-group-menu {
  padding-bottom: 10px;
}

.lan-subnets-toolbar {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 15px;
}

.network-action-buttons,
.policies-action-buttons,
.header-controls-toolbar > div {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.controls-input-wrapper {
  max-width: 180px;
  flex: 0 0 30%;
  padding-bottom: 10px;
}

.audit-log-filters-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.audit-log-filters-select {
  width: 300px;
}

.scheduled-policy-title {
  margin-bottom: 0;
}

.lan-subnets-search {
  width: 300px;
  margin-right: 10px;
}

.bp3-input {
  border: 1px solid #ddd;
  box-shadow: none;
  line-height: 34px;
  height: 34px;
  color: #76838f;
  font-weight: 300;
}

.bp3-input:disabled {
  cursor: default !important;
  -webkit-text-fill-color: rgba(92, 112, 128, 0.5);
  opacity: 1;
}

.bp3-input.bp3-large {
  font-size: 15px;
  height: 35px;
}

.bp3-select select {
  box-shadow: inset 0 0 0 1px #ddd, inset 0 -1px 0 #ddd;
  height: 34px;
}

.btn-login {
  height: 38px;
}

.import-btn {
  padding: 3px 10px;
  background-color: #46be8d;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
}

.export-btn {
  padding: 3px 10px;
  background-color: #008ef1;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
}

.export-csv-btn:hover,
.export-csv-btn:focus {
  text-decoration: none;
}

.export-btn:hover {
  background-color: #106ba3;
}

.import-btn:hover {
  background-color: #0d8050;
}

.import-btn:hover,
.export-btn:hover {
  color: #fff;
}

.bp3-label {
  color: #37474f;
  font-weight: 400;
}

.bp3-label.label-container {
  display: flex;
  align-items: center;
}

.bp3-label.label-container.label-indent {
  margin-bottom: 15px;
}

.bp3-button {
  padding-top: 3px;
  padding-bottom: 3px;
  box-shadow: none !important;
}

.bp3-button[class*="bp3-icon-"]::before {
  color: #76838f;
}

.bp3-button:not([class*="bp3-intent"]) {
  background-image: none;
}

.bp3-button.bp3-minimal {
  background-color: initial;
  background-image: none;
}

.bp3-button.bp3-right-indent {
  margin-right: 5px;
}

.bp3-button.bp3-small {
  font-size: 12px;
}

.bp3-button:hover,
.bp3-button:active {
  box-shadow: none !important;
}

.bp3-button.bp3-intent-primary:not(.bp3-outlined) {
  background-color: #008ef1;
}

.bp3-button.bp3-intent-success {
  background-color: #46be8d;
}

.bp3-toast.bp3-intent-success {
  background-color: #46be8d;
}

.bp3-button.read-only {
  cursor: default;
  color: #76838f;
}

.bp3-button.read-only:hover {
  cursor: default;
  background-color: #e0e7e9;
}

.bp3-control-group {
  flex-wrap: wrap !important;
}

.bp3-select select {
  box-shadow: none;
  border: 1px solid #ddd;
  background-color: transparent;
}

.bp3-select select:hover {
  background-color: transparent;
  background-image: none;
  border: 1px solid #d2d1d1;
}

.bp3-select.bp3-fill.bp3-intent-danger select {
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0),
    inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
}

#tab-container {
  height: 51px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  margin-top: -20px;
  -webkit-overflow-scrolling: touch;
}

#tab-container .nav {
  display: flex;
  white-space: nowrap;
  border-bottom: 0px;
  margin-bottom: 0px;
}

.custom-tabs {
  display: inline-block;
  float: none;
}

.bp3-navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.bp3-navbar-desktop {
  height: 66px;
}

.bp3-navbar-mobile {
  height: auto;
  padding-bottom: 10px;
}

.bp3-navbar-group {
  height: 66px;
}

.bp3-navbar-heading {
  width: 220px;
  margin-left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bp3-navbar-heading img {
  height: 66px;
  padding: 6px;
}

.header-mobile-menu .bp3-menu-item .avatar-mobile-menu-item {
  max-width: 57.5vw;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -3px;
}

.bp3-menu-item.bp3-intent-primary,
.bp3-menu-item.bp3-intent-primary::before,
.bp3-menu-item.bp3-intent-primary::after,
.bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
  color: #2196f3;
}

.bp3-dialog {
  background-color: #fff;
}

.bp3-dialog-body {
  margin-bottom: 0;
}

.bp3-card:not(.Card) {
  box-shadow: none;
  margin-bottom: 30px;
}

.bp3-callout.bp3-intent-primary {
  color: #106ba3;
}
.bp3-callout.bp3-intent-success {
  color: #0d8050;
}
.bp3-callout.bp3-intent-warning {
  color: #bf7326;
}
.bp3-callout.bp3-intent-danger {
  color: #c23030;
}

.bp3-tag.bp3-intent-primary {
  background-color: #0498f3;
}

.bp3-control input:checked ~ .bp3-control-indicator,
.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
  background-color: #0498f3;
}

.bp3-control.bp3-checkbox {
  color: #37474f !important;
  font-weight: 400;
}

.form-control.search-organization-input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e4eaec;
}

.form-control.search-organization-input:focus {
  border-bottom-color: #62a8ea;
}

@media screen and (max-width: 992px) {
  .map-box-container {
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .bp3-card {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 480px) {
  .header-mobile-menu {
    min-width: 48.5vw;
  }

  .header-mobile-menu .header-mobile-submenu {
    max-width: 45vw;
  }

  .header-mobile-menu .bp3-menu {
    min-width: 46.5vw;
    max-height: 50vh;
    overflow-y: auto;
  }

  .col-xs-6.overview-card {
    width: 100%;
  }

  .export-csv-container {
    width: 100%;
  }

  .query-log-search {
    margin-bottom: 15px;
  }

  .audit-log-filters-select,
  .lan-subnets-search {
    width: 100%;
  }

  .scheduled-fields-container {
    flex-direction: column;
  }

  .scheduled-field-item {
    padding-top: 15px;
  }

  .deployment-toolbar-controls {
    flex-direction: column;
    width: 100%;
  }

  .scheduled-field-item {
    padding-top: 15px;
  }

  .deployment-toolbar-search {
    width: 100%;
    margin: 0px 0px 10px;
  }

  .deployment-toolbar-select {
    width: 100%;
  }

  .controls-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .controls-input-wrapper {
    width: 100%;
    max-width: none;
    flex: none;
  }

  .controls-toolbar .btn-group.btn-group-menu > .btn {
    padding: 6px 0.85rem;
  }

  .assigned-ips-container {
    display: flex;
    flex-direction: column;
    height: 41px;
    justify-content: space-between;
  }

  .assigned-ips-container .label {
    line-height: 18px;
  }

  .btn-group-mobile {
    padding: 5px 0px;
  }

  .billing-org-row {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0px;
  }

  .billing-org-select {
    width: 100%;
  }

  .bp3-input.bp3-fill.billing-org-input {
    width: 100%;
    margin-top: 10px;
  }

  .billing-org-input-label {
    margin: 10px 0px 0px;
  }

  .billing-org-action-buttons {
    margin-top: 10px;
  }

  .install-user-agents-list,
  .disable-user-agent-container {
    flex-direction: column;
  }

  .user-agent-section {
    width: 100%;
    flex: 1;
  }

  .secret-key-container {
    margin-right: 0;
  }

  .secret-key-input-wrapper {
    flex: none;
    width: 100%;
    margin-right: 0px;
  }

  .user-agent-section:first-child > .install-dns-roster {
    margin-bottom: 30px;
  }

  .select-site-input-wrapper,
  .disable-pin-wrapper {
    width: 100%;
    max-width: none;
  }

  .filter-select {
    width: 50%;
  }

  .select-period-input {
    width: 100%;
  }

  .select-custom-period {
    flex-direction: column;
  }

  .select-custom-period .bp3-input-group {
    width: 100%;
  }

  .select-custom-period .bp3-input-group:first-child {
    margin-bottom: 10px;
  }

  .select-period-buttons {
    margin-top: 10px;
  }

  .submit-select-period {
    margin: 0;
  }

  .policy-categories-container.row {
    margin-right: -5px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 400px) {
  .filter-select {
    width: 100%;
  }
  .select-custom-period {
    padding-left: 40px;
  }
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: 15px;
}

.mb2 {
  margin-bottom: 20px;
}

.mb3 {
  margin-bottom: 30px;
}

@media print {
  body {
    padding-top: 0;
  }

  .bp3-navbar {
    display: none;
  }

  .page-content {
    margin-top: -66px;
  }
}

table.light-table {
  width: 100%;
}

table.light-table thead tr th {
  border-bottom: 1px solid #f3f3f3;
  color: #526069;
  padding: 5px;
}

table.light-table tfoot tr th {
  border-bottom: 1px solid #f3f3f3;
  color: #526069;
  padding: 5px;
}

table.light-table tfoot tr td {
  border-bottom: 1px solid #f3f3f3;
  padding: 5px;
}

table.light-table tbody tr th {
  border-bottom: 1px solid #f3f3f3;
  background-color: #f9f9f9;
  color: #526069;
  padding: 5px;
}

table.light-table tbody tr td {
  border-bottom: 1px solid #f3f3f3;
  padding: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-shadow: none;
}

/* hide drift widget when printing */
@media print {
  #drift-widget-container,
  #drift-widget-container * {
    display: none !important;
  }
}

.react-tel-input input {
  border-color: #ddd !important;
  width: 100% !important;
  height: 34px !important;
}

.react-tel-input .flag-dropdown {
  border-color: #ddd !important;
}

.animated {
  transition: all 1s ease;
}

.Select-menu-outer {
  /*height: 205px !important;*/
  max-height: 205px !important;
}

.Select-menu {
  max-height: 205px !important;
}

#drift-widget {
  left: 20px !important;
}

.list-row a {
  text-decoration: underline;
}

.back-button {
  color: #0498f5;
  border: none;
}

.back-button:hover {
  color: #76dcbc;
  text-decoration: underline;
}

.no-result-message {
  position: absolute;
  border: 1px solid #ddd;
  border-top: 0;
  background-color: #fff;
  padding: 6px 16px;
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.5);
  width: 100%;
  font-weight: 600;
  color: #333;
  border-radius: 0px 0px 3px 3px;
  z-index: 50;
}

@media screen and (min-width: 480px) {
  .csv-actions {
    display: flex;
    width: 310px;
  }
}

.csv-actions-mobile {
  display: block;
  width: 100%;
}

.bp3-label-custom {
  margin-bottom: 5px;
  color: #37474f;
  font-weight: 400;
}

.bp3-select select:disabled {
  background-color: rgba(206, 217, 224, 0.5);
}

.bp3-button:not([class*="bp3-intent-"]):active,
.bp3-button:not([class*="bp3-intent-"]).bp3-active {
  background-color: #76838f;
}

.bp3-button:not([class*="bp3-intent-"]):active
  .bp3-icon:not([class*="bp3-intent"]),
.bp3-button:not([class*="bp3-intent-"]).bp3-active
  .bp3-icon:not([class*="bp3-intent"]) {
  color: #e0e7e9;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bp3-form-group .Select.bp3-intent-danger .Select-control {
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0),
    inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
}

td:has(> div.click-to-edit-container > span.yellowBoxAlert) {
  background-color: #fcf8e7;
}
